import { validation } from '@upa/design-system';

export default [
  {
    positionFormGroup: 0,
    title: 'Begleitbrief',
    fieldList: [
      {
        type: 'select',
        name: 'cover_letter',
        label: 'Begleitbrief',
        required: true,
        disabled: false,
        width: 6,
        position: 1,
        optionList: 'coverLetters.data',
        entityName: 'cover_letter',
        validationList: [
          {
            message: 'Cover letter required',
            rule: validation.required,
          },
        ],
      },
      {
        type: 'text',
        name: 'additional_text',
        label: 'Begleitbriefkommentar',
        required: false,
        disabled: false,
        width: 12,
        value: '',
        position: 1,
      },
    ],
  },
];
