<script setup lang="ts">
import { Title, Logo, Button } from '@upa/design-system';

const router = useRouter();
// const localePath = useLocalePath();
// const { formatLink } = useInternationalization(localePath);

withDefaults(
  defineProps<{
    errorCode?: number;
    errorMessage?: string;
  }>(),
  {
    errorCode: 404,
    errorMessage: '',
  },
);

/**
 * TODO: FIX translations and routing to homepage
 * Why does "router.push(`${locale.value}`)" leads to /en-US/??
 */

const isLoading = ref(false);
const backToHome = async () => {
  // await router.push(`${locale.value}`);
  await router.push('/de');
  isLoading.value = true;
};
</script>

<script lang="ts">
export default { name: 'ErrorPage' };
</script>

<template>
  <Logo link="https://www.myswitzerland.com/" :src="src" class="header__logo" />
  <div class="header__bottom">
    <Title content="Broschüren" level="h1" class="header__title" />
  </div>

  <!-- TODO: replace with approved loading icon -->
  <div
    v-if="isLoading"
    class="flex justify-center items-center loading-icon-container--height default-error__loading"
  >
    <div role="status">
      <div
        class="h-15 w-15 i-carbon-progress-bar-round animate-spin c-gray-300 flex justify-center"
      />
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div v-else class="default-error__content">
    <Title level="h3" content="Page not found" />
    <div class="error__hepler-text">
      We are sorry, the page you're looking for could not be found. It may no
      longer exist or may have been moved.
    </div>
    <Button primary content="Back to home" @click-button="backToHome" />
  </div>
  <div class="breadcrumb">
    <!-- add breadcrumb -->
  </div>
</template>

<style lang="scss" scoped>
.header__logo {
  width: 90px;
}
.default-error__loading {
  height: calc(100vh - 253px);

  @media (max-width: 767px) {
    height: calc(100vh - 198px);
  }
}
.default-error__content {
  height: calc(100vh - 253px - 59px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 90px;

  @media (max-width: 767px) {
    height: calc(100vh - 198px - 57px);
  }

  .title {
    text-align: center;
    margin: 0;
    font-size: 24px;
  }

  .error__hepler-text {
    text-align: center;
    padding: 40px 0;
    color: var(--_c-black-secondary);
    line-height: 22px;
  }
}

.breadcrumb {
  height: 59px;
  background-color: #f1f2f3;

  @media (max-width: 767px) {
    height: 57px;
  }
}
</style>
