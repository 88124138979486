export type STORE_ID = 'consumer-store' | 'professional-store' | 'stnet-store';

export function useStore() {
  const config = useRuntimeConfig();

  const storeId = config.public.storeId as STORE_ID;
  const isProfessionalStore = storeId === 'professional-store';
  const isConsumerStore = storeId === 'consumer-store';
  const isStnetStore = storeId === 'stnet-store';

  return {
    storeId,
    isProfessionalStore,
    isConsumerStore,
    isStnetStore,
  };
}
