import { default as _91_46_46_46all_93hPBVBoTqrrMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue?macro=true";
import { default as addresshHnRQjFJWPMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue?macro=true";
import { default as indexQu7bHXNyi9Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue?macro=true";
import { default as order8xbI84GYUqMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue?macro=true";
import { default as paymentPGaNyU2jHoMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue?macro=true";
import { default as profilePd4kt4J2SAMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue?macro=true";
import { default as recover8ENK7MDzDSMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue?macro=true";
import { default as indexM18bDV2h9SMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue?macro=true";
import { default as indexV9ubT6jwHXMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue?macro=true";
import { default as errorWezjSmBPsZMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue?macro=true";
import { default as loginhPMTVGFPQHMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue?macro=true";
import { default as maintenanceKQyo11uMspMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue?macro=true";
import { default as newsletter_45subscribetdAmUCYdwPMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue?macro=true";
import { default as registerHKobSP6e19Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue?macro=true";
import { default as reset_45password1HkWOm2phYMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue?macro=true";
import { default as search2E7YUNedvR8Meta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue?macro=true";
import { default as wishlistWnq1pe7TIoMeta } from "/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___de-AT",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/de-AT/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___ja",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/ja/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___nl",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/nl/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en-GB",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en-GB/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___de",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/de/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___de-DE",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/de-DE/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___de-CH",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/de-CH/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en-IN",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en-IN/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en-CH",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en-CH/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___it-CH",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/it-CH/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___fr-CH",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/fr-CH/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___pl",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/pl/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___zo",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/zo/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___zh",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/zh/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___it-IT",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/it-IT/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___it",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/it/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en-US",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en-US/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___ru",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/ru/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___es",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/es/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___ko",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/ko/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___fr",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/fr/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___fr-CA",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/fr-CA/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___fr-FR",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/fr-FR/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___pt",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/pt/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46all_93hPBVBoTqrrMeta?.name ?? "all___en-CA",
    path: _91_46_46_46all_93hPBVBoTqrrMeta?.path ?? "/en-CA/:all(.*)*",
    meta: _91_46_46_46all_93hPBVBoTqrrMeta || {},
    alias: _91_46_46_46all_93hPBVBoTqrrMeta?.alias || [],
    redirect: _91_46_46_46all_93hPBVBoTqrrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___de-AT",
    path: addresshHnRQjFJWPMeta?.path ?? "/de-AT/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___ja",
    path: addresshHnRQjFJWPMeta?.path ?? "/ja/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___nl",
    path: addresshHnRQjFJWPMeta?.path ?? "/nl/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en-GB",
    path: addresshHnRQjFJWPMeta?.path ?? "/en-GB/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___de",
    path: addresshHnRQjFJWPMeta?.path ?? "/de/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address",
    path: addresshHnRQjFJWPMeta?.path ?? "/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___de-DE",
    path: addresshHnRQjFJWPMeta?.path ?? "/de-DE/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___de-CH",
    path: addresshHnRQjFJWPMeta?.path ?? "/de-CH/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en-IN",
    path: addresshHnRQjFJWPMeta?.path ?? "/en-IN/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en-CH",
    path: addresshHnRQjFJWPMeta?.path ?? "/en-CH/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___it-CH",
    path: addresshHnRQjFJWPMeta?.path ?? "/it-CH/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___fr-CH",
    path: addresshHnRQjFJWPMeta?.path ?? "/fr-CH/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___pl",
    path: addresshHnRQjFJWPMeta?.path ?? "/pl/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___zo",
    path: addresshHnRQjFJWPMeta?.path ?? "/zo/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___zh",
    path: addresshHnRQjFJWPMeta?.path ?? "/zh/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___it-IT",
    path: addresshHnRQjFJWPMeta?.path ?? "/it-IT/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___it",
    path: addresshHnRQjFJWPMeta?.path ?? "/it/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en-US",
    path: addresshHnRQjFJWPMeta?.path ?? "/en-US/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___ru",
    path: addresshHnRQjFJWPMeta?.path ?? "/ru/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___es",
    path: addresshHnRQjFJWPMeta?.path ?? "/es/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___ko",
    path: addresshHnRQjFJWPMeta?.path ?? "/ko/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___fr",
    path: addresshHnRQjFJWPMeta?.path ?? "/fr/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___fr-CA",
    path: addresshHnRQjFJWPMeta?.path ?? "/fr-CA/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en",
    path: addresshHnRQjFJWPMeta?.path ?? "/en/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___fr-FR",
    path: addresshHnRQjFJWPMeta?.path ?? "/fr-FR/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___pt",
    path: addresshHnRQjFJWPMeta?.path ?? "/pt/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: addresshHnRQjFJWPMeta?.name ?? "account-address___en-CA",
    path: addresshHnRQjFJWPMeta?.path ?? "/en-CA/account/address",
    meta: addresshHnRQjFJWPMeta || {},
    alias: addresshHnRQjFJWPMeta?.alias || [],
    redirect: addresshHnRQjFJWPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/address.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___de-AT",
    path: indexQu7bHXNyi9Meta?.path ?? "/de-AT/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___ja",
    path: indexQu7bHXNyi9Meta?.path ?? "/ja/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___nl",
    path: indexQu7bHXNyi9Meta?.path ?? "/nl/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en-GB",
    path: indexQu7bHXNyi9Meta?.path ?? "/en-GB/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___de",
    path: indexQu7bHXNyi9Meta?.path ?? "/de/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account",
    path: indexQu7bHXNyi9Meta?.path ?? "/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___de-DE",
    path: indexQu7bHXNyi9Meta?.path ?? "/de-DE/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___de-CH",
    path: indexQu7bHXNyi9Meta?.path ?? "/de-CH/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en-IN",
    path: indexQu7bHXNyi9Meta?.path ?? "/en-IN/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en-CH",
    path: indexQu7bHXNyi9Meta?.path ?? "/en-CH/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___it-CH",
    path: indexQu7bHXNyi9Meta?.path ?? "/it-CH/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___fr-CH",
    path: indexQu7bHXNyi9Meta?.path ?? "/fr-CH/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___pl",
    path: indexQu7bHXNyi9Meta?.path ?? "/pl/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___zo",
    path: indexQu7bHXNyi9Meta?.path ?? "/zo/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___zh",
    path: indexQu7bHXNyi9Meta?.path ?? "/zh/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___it-IT",
    path: indexQu7bHXNyi9Meta?.path ?? "/it-IT/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___it",
    path: indexQu7bHXNyi9Meta?.path ?? "/it/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en-US",
    path: indexQu7bHXNyi9Meta?.path ?? "/en-US/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___ru",
    path: indexQu7bHXNyi9Meta?.path ?? "/ru/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___es",
    path: indexQu7bHXNyi9Meta?.path ?? "/es/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___ko",
    path: indexQu7bHXNyi9Meta?.path ?? "/ko/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___fr",
    path: indexQu7bHXNyi9Meta?.path ?? "/fr/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___fr-CA",
    path: indexQu7bHXNyi9Meta?.path ?? "/fr-CA/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en",
    path: indexQu7bHXNyi9Meta?.path ?? "/en/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___fr-FR",
    path: indexQu7bHXNyi9Meta?.path ?? "/fr-FR/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___pt",
    path: indexQu7bHXNyi9Meta?.path ?? "/pt/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexQu7bHXNyi9Meta?.name ?? "account___en-CA",
    path: indexQu7bHXNyi9Meta?.path ?? "/en-CA/account",
    meta: indexQu7bHXNyi9Meta || {},
    alias: indexQu7bHXNyi9Meta?.alias || [],
    redirect: indexQu7bHXNyi9Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___de-AT",
    path: order8xbI84GYUqMeta?.path ?? "/de-AT/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___ja",
    path: order8xbI84GYUqMeta?.path ?? "/ja/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___nl",
    path: order8xbI84GYUqMeta?.path ?? "/nl/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en-GB",
    path: order8xbI84GYUqMeta?.path ?? "/en-GB/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___de",
    path: order8xbI84GYUqMeta?.path ?? "/de/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order",
    path: order8xbI84GYUqMeta?.path ?? "/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___de-DE",
    path: order8xbI84GYUqMeta?.path ?? "/de-DE/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___de-CH",
    path: order8xbI84GYUqMeta?.path ?? "/de-CH/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en-IN",
    path: order8xbI84GYUqMeta?.path ?? "/en-IN/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en-CH",
    path: order8xbI84GYUqMeta?.path ?? "/en-CH/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___it-CH",
    path: order8xbI84GYUqMeta?.path ?? "/it-CH/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___fr-CH",
    path: order8xbI84GYUqMeta?.path ?? "/fr-CH/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___pl",
    path: order8xbI84GYUqMeta?.path ?? "/pl/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___zo",
    path: order8xbI84GYUqMeta?.path ?? "/zo/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___zh",
    path: order8xbI84GYUqMeta?.path ?? "/zh/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___it-IT",
    path: order8xbI84GYUqMeta?.path ?? "/it-IT/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___it",
    path: order8xbI84GYUqMeta?.path ?? "/it/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en-US",
    path: order8xbI84GYUqMeta?.path ?? "/en-US/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___ru",
    path: order8xbI84GYUqMeta?.path ?? "/ru/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___es",
    path: order8xbI84GYUqMeta?.path ?? "/es/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___ko",
    path: order8xbI84GYUqMeta?.path ?? "/ko/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___fr",
    path: order8xbI84GYUqMeta?.path ?? "/fr/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___fr-CA",
    path: order8xbI84GYUqMeta?.path ?? "/fr-CA/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en",
    path: order8xbI84GYUqMeta?.path ?? "/en/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___fr-FR",
    path: order8xbI84GYUqMeta?.path ?? "/fr-FR/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___pt",
    path: order8xbI84GYUqMeta?.path ?? "/pt/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: order8xbI84GYUqMeta?.name ?? "account-order___en-CA",
    path: order8xbI84GYUqMeta?.path ?? "/en-CA/account/order",
    meta: order8xbI84GYUqMeta || {},
    alias: order8xbI84GYUqMeta?.alias || [],
    redirect: order8xbI84GYUqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/order.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___de-AT",
    path: paymentPGaNyU2jHoMeta?.path ?? "/de-AT/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___ja",
    path: paymentPGaNyU2jHoMeta?.path ?? "/ja/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___nl",
    path: paymentPGaNyU2jHoMeta?.path ?? "/nl/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en-GB",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en-GB/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___de",
    path: paymentPGaNyU2jHoMeta?.path ?? "/de/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment",
    path: paymentPGaNyU2jHoMeta?.path ?? "/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___de-DE",
    path: paymentPGaNyU2jHoMeta?.path ?? "/de-DE/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___de-CH",
    path: paymentPGaNyU2jHoMeta?.path ?? "/de-CH/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en-IN",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en-IN/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en-CH",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en-CH/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___it-CH",
    path: paymentPGaNyU2jHoMeta?.path ?? "/it-CH/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___fr-CH",
    path: paymentPGaNyU2jHoMeta?.path ?? "/fr-CH/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___pl",
    path: paymentPGaNyU2jHoMeta?.path ?? "/pl/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___zo",
    path: paymentPGaNyU2jHoMeta?.path ?? "/zo/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___zh",
    path: paymentPGaNyU2jHoMeta?.path ?? "/zh/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___it-IT",
    path: paymentPGaNyU2jHoMeta?.path ?? "/it-IT/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___it",
    path: paymentPGaNyU2jHoMeta?.path ?? "/it/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en-US",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en-US/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___ru",
    path: paymentPGaNyU2jHoMeta?.path ?? "/ru/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___es",
    path: paymentPGaNyU2jHoMeta?.path ?? "/es/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___ko",
    path: paymentPGaNyU2jHoMeta?.path ?? "/ko/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___fr",
    path: paymentPGaNyU2jHoMeta?.path ?? "/fr/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___fr-CA",
    path: paymentPGaNyU2jHoMeta?.path ?? "/fr-CA/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___fr-FR",
    path: paymentPGaNyU2jHoMeta?.path ?? "/fr-FR/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___pt",
    path: paymentPGaNyU2jHoMeta?.path ?? "/pt/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: paymentPGaNyU2jHoMeta?.name ?? "account-payment___en-CA",
    path: paymentPGaNyU2jHoMeta?.path ?? "/en-CA/account/payment",
    meta: paymentPGaNyU2jHoMeta || {},
    alias: paymentPGaNyU2jHoMeta?.alias || [],
    redirect: paymentPGaNyU2jHoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___de-AT",
    path: profilePd4kt4J2SAMeta?.path ?? "/de-AT/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___ja",
    path: profilePd4kt4J2SAMeta?.path ?? "/ja/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___nl",
    path: profilePd4kt4J2SAMeta?.path ?? "/nl/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en-GB",
    path: profilePd4kt4J2SAMeta?.path ?? "/en-GB/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___de",
    path: profilePd4kt4J2SAMeta?.path ?? "/de/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile",
    path: profilePd4kt4J2SAMeta?.path ?? "/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___de-DE",
    path: profilePd4kt4J2SAMeta?.path ?? "/de-DE/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___de-CH",
    path: profilePd4kt4J2SAMeta?.path ?? "/de-CH/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en-IN",
    path: profilePd4kt4J2SAMeta?.path ?? "/en-IN/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en-CH",
    path: profilePd4kt4J2SAMeta?.path ?? "/en-CH/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___it-CH",
    path: profilePd4kt4J2SAMeta?.path ?? "/it-CH/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___fr-CH",
    path: profilePd4kt4J2SAMeta?.path ?? "/fr-CH/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___pl",
    path: profilePd4kt4J2SAMeta?.path ?? "/pl/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___zo",
    path: profilePd4kt4J2SAMeta?.path ?? "/zo/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___zh",
    path: profilePd4kt4J2SAMeta?.path ?? "/zh/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___it-IT",
    path: profilePd4kt4J2SAMeta?.path ?? "/it-IT/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___it",
    path: profilePd4kt4J2SAMeta?.path ?? "/it/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en-US",
    path: profilePd4kt4J2SAMeta?.path ?? "/en-US/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___ru",
    path: profilePd4kt4J2SAMeta?.path ?? "/ru/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___es",
    path: profilePd4kt4J2SAMeta?.path ?? "/es/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___ko",
    path: profilePd4kt4J2SAMeta?.path ?? "/ko/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___fr",
    path: profilePd4kt4J2SAMeta?.path ?? "/fr/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___fr-CA",
    path: profilePd4kt4J2SAMeta?.path ?? "/fr-CA/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en",
    path: profilePd4kt4J2SAMeta?.path ?? "/en/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___fr-FR",
    path: profilePd4kt4J2SAMeta?.path ?? "/fr-FR/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___pt",
    path: profilePd4kt4J2SAMeta?.path ?? "/pt/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilePd4kt4J2SAMeta?.name ?? "account-profile___en-CA",
    path: profilePd4kt4J2SAMeta?.path ?? "/en-CA/account/profile",
    meta: profilePd4kt4J2SAMeta || {},
    alias: profilePd4kt4J2SAMeta?.alias || [],
    redirect: profilePd4kt4J2SAMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___de-AT",
    path: recover8ENK7MDzDSMeta?.path ?? "/de-AT/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___ja",
    path: recover8ENK7MDzDSMeta?.path ?? "/ja/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___nl",
    path: recover8ENK7MDzDSMeta?.path ?? "/nl/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en-GB",
    path: recover8ENK7MDzDSMeta?.path ?? "/en-GB/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___de",
    path: recover8ENK7MDzDSMeta?.path ?? "/de/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover",
    path: recover8ENK7MDzDSMeta?.path ?? "/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___de-DE",
    path: recover8ENK7MDzDSMeta?.path ?? "/de-DE/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___de-CH",
    path: recover8ENK7MDzDSMeta?.path ?? "/de-CH/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en-IN",
    path: recover8ENK7MDzDSMeta?.path ?? "/en-IN/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en-CH",
    path: recover8ENK7MDzDSMeta?.path ?? "/en-CH/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___it-CH",
    path: recover8ENK7MDzDSMeta?.path ?? "/it-CH/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___fr-CH",
    path: recover8ENK7MDzDSMeta?.path ?? "/fr-CH/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___pl",
    path: recover8ENK7MDzDSMeta?.path ?? "/pl/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___zo",
    path: recover8ENK7MDzDSMeta?.path ?? "/zo/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___zh",
    path: recover8ENK7MDzDSMeta?.path ?? "/zh/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___it-IT",
    path: recover8ENK7MDzDSMeta?.path ?? "/it-IT/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___it",
    path: recover8ENK7MDzDSMeta?.path ?? "/it/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en-US",
    path: recover8ENK7MDzDSMeta?.path ?? "/en-US/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___ru",
    path: recover8ENK7MDzDSMeta?.path ?? "/ru/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___es",
    path: recover8ENK7MDzDSMeta?.path ?? "/es/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___ko",
    path: recover8ENK7MDzDSMeta?.path ?? "/ko/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___fr",
    path: recover8ENK7MDzDSMeta?.path ?? "/fr/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___fr-CA",
    path: recover8ENK7MDzDSMeta?.path ?? "/fr-CA/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en",
    path: recover8ENK7MDzDSMeta?.path ?? "/en/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___fr-FR",
    path: recover8ENK7MDzDSMeta?.path ?? "/fr-FR/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___pt",
    path: recover8ENK7MDzDSMeta?.path ?? "/pt/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: recover8ENK7MDzDSMeta?.name ?? "account-recover___en-CA",
    path: recover8ENK7MDzDSMeta?.path ?? "/en-CA/account/recover",
    meta: recover8ENK7MDzDSMeta || {},
    alias: recover8ENK7MDzDSMeta?.alias || [],
    redirect: recover8ENK7MDzDSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/account/recover.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___de-AT",
    path: indexM18bDV2h9SMeta?.path ?? "/de-AT/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___ja",
    path: indexM18bDV2h9SMeta?.path ?? "/ja/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___nl",
    path: indexM18bDV2h9SMeta?.path ?? "/nl/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en-GB",
    path: indexM18bDV2h9SMeta?.path ?? "/en-GB/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___de",
    path: indexM18bDV2h9SMeta?.path ?? "/de/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout",
    path: indexM18bDV2h9SMeta?.path ?? "/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___de-DE",
    path: indexM18bDV2h9SMeta?.path ?? "/de-DE/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___de-CH",
    path: indexM18bDV2h9SMeta?.path ?? "/de-CH/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en-IN",
    path: indexM18bDV2h9SMeta?.path ?? "/en-IN/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en-CH",
    path: indexM18bDV2h9SMeta?.path ?? "/en-CH/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___it-CH",
    path: indexM18bDV2h9SMeta?.path ?? "/it-CH/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___fr-CH",
    path: indexM18bDV2h9SMeta?.path ?? "/fr-CH/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___pl",
    path: indexM18bDV2h9SMeta?.path ?? "/pl/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___zo",
    path: indexM18bDV2h9SMeta?.path ?? "/zo/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___zh",
    path: indexM18bDV2h9SMeta?.path ?? "/zh/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___it-IT",
    path: indexM18bDV2h9SMeta?.path ?? "/it-IT/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___it",
    path: indexM18bDV2h9SMeta?.path ?? "/it/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en-US",
    path: indexM18bDV2h9SMeta?.path ?? "/en-US/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___ru",
    path: indexM18bDV2h9SMeta?.path ?? "/ru/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___es",
    path: indexM18bDV2h9SMeta?.path ?? "/es/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___ko",
    path: indexM18bDV2h9SMeta?.path ?? "/ko/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___fr",
    path: indexM18bDV2h9SMeta?.path ?? "/fr/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___fr-CA",
    path: indexM18bDV2h9SMeta?.path ?? "/fr-CA/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en",
    path: indexM18bDV2h9SMeta?.path ?? "/en/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___fr-FR",
    path: indexM18bDV2h9SMeta?.path ?? "/fr-FR/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___pt",
    path: indexM18bDV2h9SMeta?.path ?? "/pt/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexM18bDV2h9SMeta?.name ?? "checkout___en-CA",
    path: indexM18bDV2h9SMeta?.path ?? "/en-CA/checkout",
    meta: indexM18bDV2h9SMeta || {},
    alias: indexM18bDV2h9SMeta?.alias || [],
    redirect: indexM18bDV2h9SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___de-AT",
    path: indexV9ubT6jwHXMeta?.path ?? "/de-AT/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___ja",
    path: indexV9ubT6jwHXMeta?.path ?? "/ja/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___nl",
    path: indexV9ubT6jwHXMeta?.path ?? "/nl/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en-GB",
    path: indexV9ubT6jwHXMeta?.path ?? "/en-GB/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___de",
    path: indexV9ubT6jwHXMeta?.path ?? "/de/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success",
    path: indexV9ubT6jwHXMeta?.path ?? "/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___de-DE",
    path: indexV9ubT6jwHXMeta?.path ?? "/de-DE/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___de-CH",
    path: indexV9ubT6jwHXMeta?.path ?? "/de-CH/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en-IN",
    path: indexV9ubT6jwHXMeta?.path ?? "/en-IN/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en-CH",
    path: indexV9ubT6jwHXMeta?.path ?? "/en-CH/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___it-CH",
    path: indexV9ubT6jwHXMeta?.path ?? "/it-CH/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___fr-CH",
    path: indexV9ubT6jwHXMeta?.path ?? "/fr-CH/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___pl",
    path: indexV9ubT6jwHXMeta?.path ?? "/pl/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___zo",
    path: indexV9ubT6jwHXMeta?.path ?? "/zo/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___zh",
    path: indexV9ubT6jwHXMeta?.path ?? "/zh/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___it-IT",
    path: indexV9ubT6jwHXMeta?.path ?? "/it-IT/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___it",
    path: indexV9ubT6jwHXMeta?.path ?? "/it/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en-US",
    path: indexV9ubT6jwHXMeta?.path ?? "/en-US/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___ru",
    path: indexV9ubT6jwHXMeta?.path ?? "/ru/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___es",
    path: indexV9ubT6jwHXMeta?.path ?? "/es/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___ko",
    path: indexV9ubT6jwHXMeta?.path ?? "/ko/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___fr",
    path: indexV9ubT6jwHXMeta?.path ?? "/fr/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___fr-CA",
    path: indexV9ubT6jwHXMeta?.path ?? "/fr-CA/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en",
    path: indexV9ubT6jwHXMeta?.path ?? "/en/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___fr-FR",
    path: indexV9ubT6jwHXMeta?.path ?? "/fr-FR/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___pt",
    path: indexV9ubT6jwHXMeta?.path ?? "/pt/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9ubT6jwHXMeta?.name ?? "checkout-success___en-CA",
    path: indexV9ubT6jwHXMeta?.path ?? "/en-CA/checkout/success",
    meta: indexV9ubT6jwHXMeta || {},
    alias: indexV9ubT6jwHXMeta?.alias || [],
    redirect: indexV9ubT6jwHXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___de-AT",
    path: errorWezjSmBPsZMeta?.path ?? "/de-AT/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___ja",
    path: errorWezjSmBPsZMeta?.path ?? "/ja/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___nl",
    path: errorWezjSmBPsZMeta?.path ?? "/nl/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en-GB",
    path: errorWezjSmBPsZMeta?.path ?? "/en-GB/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___de",
    path: errorWezjSmBPsZMeta?.path ?? "/de/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error",
    path: errorWezjSmBPsZMeta?.path ?? "/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___de-DE",
    path: errorWezjSmBPsZMeta?.path ?? "/de-DE/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___de-CH",
    path: errorWezjSmBPsZMeta?.path ?? "/de-CH/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en-IN",
    path: errorWezjSmBPsZMeta?.path ?? "/en-IN/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en-CH",
    path: errorWezjSmBPsZMeta?.path ?? "/en-CH/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___it-CH",
    path: errorWezjSmBPsZMeta?.path ?? "/it-CH/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___fr-CH",
    path: errorWezjSmBPsZMeta?.path ?? "/fr-CH/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___pl",
    path: errorWezjSmBPsZMeta?.path ?? "/pl/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___zo",
    path: errorWezjSmBPsZMeta?.path ?? "/zo/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___zh",
    path: errorWezjSmBPsZMeta?.path ?? "/zh/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___it-IT",
    path: errorWezjSmBPsZMeta?.path ?? "/it-IT/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___it",
    path: errorWezjSmBPsZMeta?.path ?? "/it/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en-US",
    path: errorWezjSmBPsZMeta?.path ?? "/en-US/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___ru",
    path: errorWezjSmBPsZMeta?.path ?? "/ru/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___es",
    path: errorWezjSmBPsZMeta?.path ?? "/es/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___ko",
    path: errorWezjSmBPsZMeta?.path ?? "/ko/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___fr",
    path: errorWezjSmBPsZMeta?.path ?? "/fr/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___fr-CA",
    path: errorWezjSmBPsZMeta?.path ?? "/fr-CA/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en",
    path: errorWezjSmBPsZMeta?.path ?? "/en/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___fr-FR",
    path: errorWezjSmBPsZMeta?.path ?? "/fr-FR/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___pt",
    path: errorWezjSmBPsZMeta?.path ?? "/pt/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: errorWezjSmBPsZMeta?.name ?? "error___en-CA",
    path: errorWezjSmBPsZMeta?.path ?? "/en-CA/error",
    meta: errorWezjSmBPsZMeta || {},
    alias: errorWezjSmBPsZMeta?.alias || [],
    redirect: errorWezjSmBPsZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/error.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___de-AT",
    path: loginhPMTVGFPQHMeta?.path ?? "/de-AT/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___ja",
    path: loginhPMTVGFPQHMeta?.path ?? "/ja/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___nl",
    path: loginhPMTVGFPQHMeta?.path ?? "/nl/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en-GB",
    path: loginhPMTVGFPQHMeta?.path ?? "/en-GB/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___de",
    path: loginhPMTVGFPQHMeta?.path ?? "/de/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login",
    path: loginhPMTVGFPQHMeta?.path ?? "/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___de-DE",
    path: loginhPMTVGFPQHMeta?.path ?? "/de-DE/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___de-CH",
    path: loginhPMTVGFPQHMeta?.path ?? "/de-CH/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en-IN",
    path: loginhPMTVGFPQHMeta?.path ?? "/en-IN/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en-CH",
    path: loginhPMTVGFPQHMeta?.path ?? "/en-CH/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___it-CH",
    path: loginhPMTVGFPQHMeta?.path ?? "/it-CH/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___fr-CH",
    path: loginhPMTVGFPQHMeta?.path ?? "/fr-CH/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___pl",
    path: loginhPMTVGFPQHMeta?.path ?? "/pl/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___zo",
    path: loginhPMTVGFPQHMeta?.path ?? "/zo/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___zh",
    path: loginhPMTVGFPQHMeta?.path ?? "/zh/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___it-IT",
    path: loginhPMTVGFPQHMeta?.path ?? "/it-IT/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___it",
    path: loginhPMTVGFPQHMeta?.path ?? "/it/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en-US",
    path: loginhPMTVGFPQHMeta?.path ?? "/en-US/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___ru",
    path: loginhPMTVGFPQHMeta?.path ?? "/ru/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___es",
    path: loginhPMTVGFPQHMeta?.path ?? "/es/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___ko",
    path: loginhPMTVGFPQHMeta?.path ?? "/ko/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___fr",
    path: loginhPMTVGFPQHMeta?.path ?? "/fr/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___fr-CA",
    path: loginhPMTVGFPQHMeta?.path ?? "/fr-CA/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en",
    path: loginhPMTVGFPQHMeta?.path ?? "/en/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___fr-FR",
    path: loginhPMTVGFPQHMeta?.path ?? "/fr-FR/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___pt",
    path: loginhPMTVGFPQHMeta?.path ?? "/pt/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhPMTVGFPQHMeta?.name ?? "login___en-CA",
    path: loginhPMTVGFPQHMeta?.path ?? "/en-CA/login",
    meta: loginhPMTVGFPQHMeta || {},
    alias: loginhPMTVGFPQHMeta?.alias || [],
    redirect: loginhPMTVGFPQHMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___de-AT",
    path: maintenanceKQyo11uMspMeta?.path ?? "/de-AT/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___ja",
    path: maintenanceKQyo11uMspMeta?.path ?? "/ja/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___nl",
    path: maintenanceKQyo11uMspMeta?.path ?? "/nl/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en-GB",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en-GB/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___de",
    path: maintenanceKQyo11uMspMeta?.path ?? "/de/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance",
    path: maintenanceKQyo11uMspMeta?.path ?? "/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___de-DE",
    path: maintenanceKQyo11uMspMeta?.path ?? "/de-DE/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___de-CH",
    path: maintenanceKQyo11uMspMeta?.path ?? "/de-CH/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en-IN",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en-IN/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en-CH",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en-CH/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___it-CH",
    path: maintenanceKQyo11uMspMeta?.path ?? "/it-CH/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___fr-CH",
    path: maintenanceKQyo11uMspMeta?.path ?? "/fr-CH/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___pl",
    path: maintenanceKQyo11uMspMeta?.path ?? "/pl/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___zo",
    path: maintenanceKQyo11uMspMeta?.path ?? "/zo/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___zh",
    path: maintenanceKQyo11uMspMeta?.path ?? "/zh/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___it-IT",
    path: maintenanceKQyo11uMspMeta?.path ?? "/it-IT/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___it",
    path: maintenanceKQyo11uMspMeta?.path ?? "/it/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en-US",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en-US/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___ru",
    path: maintenanceKQyo11uMspMeta?.path ?? "/ru/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___es",
    path: maintenanceKQyo11uMspMeta?.path ?? "/es/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___ko",
    path: maintenanceKQyo11uMspMeta?.path ?? "/ko/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___fr",
    path: maintenanceKQyo11uMspMeta?.path ?? "/fr/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___fr-CA",
    path: maintenanceKQyo11uMspMeta?.path ?? "/fr-CA/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___fr-FR",
    path: maintenanceKQyo11uMspMeta?.path ?? "/fr-FR/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___pt",
    path: maintenanceKQyo11uMspMeta?.path ?? "/pt/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: maintenanceKQyo11uMspMeta?.name ?? "maintenance___en-CA",
    path: maintenanceKQyo11uMspMeta?.path ?? "/en-CA/maintenance",
    meta: maintenanceKQyo11uMspMeta || {},
    alias: maintenanceKQyo11uMspMeta?.alias || [],
    redirect: maintenanceKQyo11uMspMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___de-AT",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/de-AT/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___ja",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/ja/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___nl",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/nl/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en-GB",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en-GB/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___de",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/de/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___de-DE",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/de-DE/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___de-CH",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/de-CH/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en-IN",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en-IN/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en-CH",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en-CH/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___it-CH",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/it-CH/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___fr-CH",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/fr-CH/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___pl",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/pl/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___zo",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/zo/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___zh",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/zh/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___it-IT",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/it-IT/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___it",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/it/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en-US",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en-US/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___ru",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/ru/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___es",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/es/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___ko",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/ko/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___fr",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/fr/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___fr-CA",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/fr-CA/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___fr-FR",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/fr-FR/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___pt",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/pt/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45subscribetdAmUCYdwPMeta?.name ?? "newsletter-subscribe___en-CA",
    path: newsletter_45subscribetdAmUCYdwPMeta?.path ?? "/en-CA/newsletter-subscribe",
    meta: newsletter_45subscribetdAmUCYdwPMeta || {},
    alias: newsletter_45subscribetdAmUCYdwPMeta?.alias || [],
    redirect: newsletter_45subscribetdAmUCYdwPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/newsletter-subscribe.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___de-AT",
    path: registerHKobSP6e19Meta?.path ?? "/de-AT/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___ja",
    path: registerHKobSP6e19Meta?.path ?? "/ja/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___nl",
    path: registerHKobSP6e19Meta?.path ?? "/nl/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en-GB",
    path: registerHKobSP6e19Meta?.path ?? "/en-GB/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___de",
    path: registerHKobSP6e19Meta?.path ?? "/de/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register",
    path: registerHKobSP6e19Meta?.path ?? "/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___de-DE",
    path: registerHKobSP6e19Meta?.path ?? "/de-DE/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___de-CH",
    path: registerHKobSP6e19Meta?.path ?? "/de-CH/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en-IN",
    path: registerHKobSP6e19Meta?.path ?? "/en-IN/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en-CH",
    path: registerHKobSP6e19Meta?.path ?? "/en-CH/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___it-CH",
    path: registerHKobSP6e19Meta?.path ?? "/it-CH/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___fr-CH",
    path: registerHKobSP6e19Meta?.path ?? "/fr-CH/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___pl",
    path: registerHKobSP6e19Meta?.path ?? "/pl/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___zo",
    path: registerHKobSP6e19Meta?.path ?? "/zo/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___zh",
    path: registerHKobSP6e19Meta?.path ?? "/zh/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___it-IT",
    path: registerHKobSP6e19Meta?.path ?? "/it-IT/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___it",
    path: registerHKobSP6e19Meta?.path ?? "/it/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en-US",
    path: registerHKobSP6e19Meta?.path ?? "/en-US/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___ru",
    path: registerHKobSP6e19Meta?.path ?? "/ru/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___es",
    path: registerHKobSP6e19Meta?.path ?? "/es/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___ko",
    path: registerHKobSP6e19Meta?.path ?? "/ko/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___fr",
    path: registerHKobSP6e19Meta?.path ?? "/fr/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___fr-CA",
    path: registerHKobSP6e19Meta?.path ?? "/fr-CA/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en",
    path: registerHKobSP6e19Meta?.path ?? "/en/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___fr-FR",
    path: registerHKobSP6e19Meta?.path ?? "/fr-FR/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___pt",
    path: registerHKobSP6e19Meta?.path ?? "/pt/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerHKobSP6e19Meta?.name ?? "register___en-CA",
    path: registerHKobSP6e19Meta?.path ?? "/en-CA/register",
    meta: registerHKobSP6e19Meta || {},
    alias: registerHKobSP6e19Meta?.alias || [],
    redirect: registerHKobSP6e19Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___de-AT",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/de-AT/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___ja",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/ja/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___nl",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/nl/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en-GB",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en-GB/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___de",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/de/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___de-DE",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/de-DE/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___de-CH",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/de-CH/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en-IN",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en-IN/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en-CH",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en-CH/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___it-CH",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/it-CH/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___fr-CH",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/fr-CH/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___pl",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/pl/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___zo",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/zo/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___zh",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/zh/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___it-IT",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/it-IT/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___it",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/it/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en-US",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en-US/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___ru",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/ru/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___es",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/es/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___ko",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/ko/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___fr",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/fr/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___fr-CA",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/fr-CA/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___fr-FR",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/fr-FR/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___pt",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/pt/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45password1HkWOm2phYMeta?.name ?? "reset-password___en-CA",
    path: reset_45password1HkWOm2phYMeta?.path ?? "/en-CA/reset-password",
    meta: reset_45password1HkWOm2phYMeta || {},
    alias: reset_45password1HkWOm2phYMeta?.alias || [],
    redirect: reset_45password1HkWOm2phYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___de-AT",
    path: search2E7YUNedvR8Meta?.path ?? "/de-AT/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___ja",
    path: search2E7YUNedvR8Meta?.path ?? "/ja/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___nl",
    path: search2E7YUNedvR8Meta?.path ?? "/nl/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en-GB",
    path: search2E7YUNedvR8Meta?.path ?? "/en-GB/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___de",
    path: search2E7YUNedvR8Meta?.path ?? "/de/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2",
    path: search2E7YUNedvR8Meta?.path ?? "/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___de-DE",
    path: search2E7YUNedvR8Meta?.path ?? "/de-DE/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___de-CH",
    path: search2E7YUNedvR8Meta?.path ?? "/de-CH/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en-IN",
    path: search2E7YUNedvR8Meta?.path ?? "/en-IN/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en-CH",
    path: search2E7YUNedvR8Meta?.path ?? "/en-CH/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___it-CH",
    path: search2E7YUNedvR8Meta?.path ?? "/it-CH/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___fr-CH",
    path: search2E7YUNedvR8Meta?.path ?? "/fr-CH/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___pl",
    path: search2E7YUNedvR8Meta?.path ?? "/pl/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___zo",
    path: search2E7YUNedvR8Meta?.path ?? "/zo/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___zh",
    path: search2E7YUNedvR8Meta?.path ?? "/zh/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___it-IT",
    path: search2E7YUNedvR8Meta?.path ?? "/it-IT/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___it",
    path: search2E7YUNedvR8Meta?.path ?? "/it/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en-US",
    path: search2E7YUNedvR8Meta?.path ?? "/en-US/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___ru",
    path: search2E7YUNedvR8Meta?.path ?? "/ru/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___es",
    path: search2E7YUNedvR8Meta?.path ?? "/es/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___ko",
    path: search2E7YUNedvR8Meta?.path ?? "/ko/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___fr",
    path: search2E7YUNedvR8Meta?.path ?? "/fr/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___fr-CA",
    path: search2E7YUNedvR8Meta?.path ?? "/fr-CA/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en",
    path: search2E7YUNedvR8Meta?.path ?? "/en/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___fr-FR",
    path: search2E7YUNedvR8Meta?.path ?? "/fr-FR/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___pt",
    path: search2E7YUNedvR8Meta?.path ?? "/pt/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: search2E7YUNedvR8Meta?.name ?? "search2___en-CA",
    path: search2E7YUNedvR8Meta?.path ?? "/en-CA/search2",
    meta: search2E7YUNedvR8Meta || {},
    alias: search2E7YUNedvR8Meta?.alias || [],
    redirect: search2E7YUNedvR8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/search2.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___de-AT",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/de-AT/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___ja",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/ja/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___nl",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/nl/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en-GB",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en-GB/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___de",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/de/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___de-DE",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/de-DE/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___de-CH",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/de-CH/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en-IN",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en-IN/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en-CH",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en-CH/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___it-CH",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/it-CH/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___fr-CH",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/fr-CH/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___pl",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/pl/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___zo",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/zo/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___zh",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/zh/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___it-IT",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/it-IT/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___it",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/it/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en-US",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en-US/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___ru",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/ru/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___es",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/es/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___ko",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/ko/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___fr",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/fr/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___fr-CA",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/fr-CA/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___fr-FR",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/fr-FR/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___pt",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/pt/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistWnq1pe7TIoMeta?.name ?? "wishlist___en-CA",
    path: wishlistWnq1pe7TIoMeta?.path ?? "/en-CA/wishlist",
    meta: wishlistWnq1pe7TIoMeta || {},
    alias: wishlistWnq1pe7TIoMeta?.alias || [],
    redirect: wishlistWnq1pe7TIoMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/apps/frontends/pages/wishlist.vue").then(m => m.default || m)
  }
]