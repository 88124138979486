import { getSessionContext } from '@shopware-pwa/api-client';

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp();
  const locale = nuxtApp.$i18n.locale.value;

  const homePath = `/${locale}`;
  const loginPath = `/${locale}/login`;
  const register = `/${locale}/register`;
  const resetPassword = `/${locale}/reset-password`;
  const accountPath = `/${locale}/account/order`;

  // Read application context
  const { isProfessionalStore } = useStore();
  const { apiInstance } = useShopwareContext();
  const contextToken = useCookie('sw-context-token');

  // Update context token and get customer info
  apiInstance.update({ contextToken: contextToken.value || '' });
  const { customer } = await getSessionContext(apiInstance);

  // Redirect to account or home page if user is logged in and trying to open login page
  if (to.path.includes(loginPath) && customer) {
    return navigateTo(isProfessionalStore ? homePath : accountPath);
  }

  // If the path in 'professional' leads to '/account', redirect to '/account/order
  if (
    isProfessionalStore &&
    (to.path === `/${locale}/account` || to.path === `/${locale}/account/`)
  ) {
    return navigateTo(accountPath);
  }

  // If the path in 'b2b/b2c' leads to '/account', redirect to '/
  if (
    !isProfessionalStore &&
    (to.path === `/${locale}/account` || to.path === `/${locale}/account/`)
  ) {
    return navigateTo(homePath);
  }

  // If it's professional store
  if (isProfessionalStore) {
    const allowed = [
      '/login',
      loginPath,
      '/register',
      register,
      '/reset-password',
      resetPassword,
    ];
    // Redirect to login page if user is not logged in and not on the login, register or reset password page
    if (!allowed.includes(to.path) && !customer) {
      return navigateTo(loginPath);
    }
  }
});
