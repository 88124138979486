import { useRoute as useBaseRoute } from '#app';
import { RouteLocationNormalizedLoaded } from 'vue-router';

interface ICustomRoute extends RouteLocationNormalizedLoaded {
  /**
   * Get full URL without locale
   */
  fullPathWithoutLocale: string;
}

const getPathWithoutLocale = (route: ICustomRoute) => {
  const url =
    typeof route.params.all === 'string'
      ? route.params.all
      : route.params.all?.join('/');

  return '/' + url;
};

export default function (): ICustomRoute {
  const route = useBaseRoute() as ICustomRoute;
  route.fullPathWithoutLocale = getPathWithoutLocale(route);

  return route;
}
