import * as Sentry from '@sentry/vue';
import { defineNuxtPlugin, useRouter } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp, $config } = nuxtApp;

  Sentry.init({
    app: [vueApp],
    dsn: $config.public.SENTRY_DSN,
    environment: process.env.STAGE || 'development',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    initialScope: { tags: { store: $config.public.storeId } },
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          {
            event,
            hint,
          },
        );
      }
      return event;
    },
  });

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  );

  Sentry.attachErrorHandler(vueApp, {
    attachProps: true,
    logErrors: false,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
