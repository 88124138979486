<!-- eslint-disable no-console -->
<script setup lang="ts">
import { getSessionContext } from '@shopware-pwa/api-client';
import { SessionContext } from '@shopware-pwa/types';
const { isConsumerStore } = useStore();

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

useHead({
  title: 'Switzerland Tourism',
  meta: [
    {
      name: 'description',
      content: 'Switzerland Tourism',
    },
  ],
  htmlAttrs: { lang: 'en' },
  script: [
    // {
    //   src: 'https://cdn.cookielaw.org/consent/0e4eb202-ab02-40ca-81ed-4f650c18b561/OtAutoBlock.js',
    //   type: 'text/javascript',
    //   async: true,
    //   defer: true,
    // },
    // {
    //   type: 'text/javascript',
    //   async: true,
    //   src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js?did=0e4eb202-ab02-40ca-81ed-4f650c18b561',
    // },
    {
      ...(isConsumerStore && {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        type: 'text/javascript',
        defer: true,
        'data-domain-script': '0e4eb202-ab02-40ca-81ed-4f650c18b561',
      }),
    },
    {
      hid: 'gtm',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K4ZGQF');`,
      type: 'text/javascript',
    },
  ],
});

const { apiInstance } = useShopwareContext();
const sessionContextData = ref();
sessionContextData.value = await getSessionContext(apiInstance);

const { locale, localeProperties } = useI18n();

usePrice({
  currencyCode: sessionContextData.value?.currency?.isoCode || '',
  localeCode: locale.value,
});

useSessionContext(sessionContextData.value as SessionContext);

//const { getWishlistProducts } = useWishlist();
const { refreshCart } = useCart();

useNotifications();
useAddress();

const router = useRouter();
const {
  getAvailableLanguages,
  changeLanguage,
  languages: storeLanguages,
} = useInternationalization();
const { languageIdChain, refreshSessionContext } = useSessionContext();

const { data: languages } = await useAsyncData('languages', async () => {
  return await getAvailableLanguages();
});

if (languages.value?.elements.length && router.currentRoute.value.name) {
  storeLanguages.value = languages.value?.elements;

  const { languageId } = localeProperties.value;

  if (languageIdChain !== languageId) {
    await changeLanguage(languageId);
    await refreshSessionContext();
  }
}

onMounted(() => {
  refreshCart();
  //getWishlistProducts();
});

// price format
const params = {
  localeCode: 'de-CH',
  currencyCode: 'CHF',
};
const { update } = usePrice();
update(params);

/**
 * so that the OffcanvasCard can be opened globally
 */
const openOffcanvas = ref(false);
provide('openOffcanvas', openOffcanvas);

/**
 * for notification in open OffcanvasCard
 */
const notificationOpen = ref(false);
provide('notificationOpen', notificationOpen);
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
// h2 {
//   @apply text-4xl py-4;
// }

select {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjNmI3MjgwJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4=');
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  appearance: none;
}
</style>
