// @ts-nocheck


export const localeCodes = [
  "de-AT",
  "ja",
  "nl",
  "en-GB",
  "de",
  "de-DE",
  "de-CH",
  "en-IN",
  "en-CH",
  "it-CH",
  "fr-CH",
  "pl",
  "zo",
  "zh",
  "it-IT",
  "it",
  "en-US",
  "ru",
  "es",
  "ko",
  "fr",
  "fr-CA",
  "en",
  "fr-FR",
  "pt",
  "en-CA"
]

export const localeMessages = { 
  "de-AT": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "ja": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "nl": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en-GB": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "de": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "de-DE": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "de-CH": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en-IN": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en-CH": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "it-CH": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "fr-CH": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "pl": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "zo": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "zh": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "it-IT": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "it": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en-US": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "ru": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "es": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "ko": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "fr": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "fr-CA": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "fr-FR": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "pt": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
  "en-CA": [
      { key: "../i18n/utils/messageReader.ts", load: () => import("../i18n/utils/messageReader.ts?hash=a225ff3a&locale=de-AT" /* webpackChunkName: "locale__opt_atlassian_pipelines_agent_build_apps_frontends_i18n_utils_messageReader_ts" */), cache: false },
  ],
}

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = {
  "experimental": {
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de-AT",
      "iso": "de-AT",
      "languageId": "7f041fa7dc6b4d9b830ba9d1ed061ebe",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "ja",
      "iso": "ja-JP",
      "languageId": "4ed13e1b86484557a1f106a44aad287f",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "languageId": "6bdf535e3b4940369a70a43e28f75cb7",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en-GB",
      "iso": "en-GB",
      "languageId": "053f020413df492c86ea58d279743cc1",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "languageId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "de-DE",
      "iso": "de-DE",
      "languageId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "de-CH",
      "iso": "de-CH",
      "languageId": "1644a5c39d3b439b917f6bd263b7f3a1",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en-IN",
      "iso": "en-IN",
      "languageId": "a7ec78935f5f40578af2a0cbc703d280",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en-CH",
      "iso": "en-CH",
      "languageId": "4081f4bda2fe4cd8afd97a44d49ea20e",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "it-CH",
      "iso": "it-CH",
      "languageId": "5f647817a9f1483d92c7b82543195369",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "fr-CH",
      "iso": "fr-CH",
      "languageId": "b3ad4309402a405cb995986fcc13e5f2",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "pl",
      "iso": "pl-PL",
      "languageId": "41f4b54c33144ba3a1f1511b38411f67",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "zo",
      "iso": "zh-HK",
      "languageId": "1a1f25c95a70424a946982d50e8978b0",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "languageId": "1a949cf9b9bd4cae8533866b6c70a5ff",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "it-IT",
      "iso": "it-IT",
      "languageId": "519a4d9fb36442f9a7536c4dc178f44e",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "languageId": "519a4d9fb36442f9a7536c4dc178f44e",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "languageId": "50ec64cb77454ee790f3b20208c1d7ea",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "ru",
      "iso": "ru-RU",
      "languageId": "6dd089602d5a489299ce3b81e04a10f4",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "languageId": "73781d40339e4a0aa93215018e866156",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "ko",
      "iso": "ko-KR",
      "languageId": "784eb941c8f843658642f7c0038ae837",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "languageId": "9cd3821035ab4754a0c3db6a27c07b4e",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "fr-CA",
      "iso": "fr-CA",
      "languageId": "2ff212d0ef184d898f8c4e70aadaf0dc",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en",
      "iso": "en-GB",
      "languageId": "053f020413df492c86ea58d279743cc1",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "fr-FR",
      "iso": "fr-FR",
      "languageId": "9cd3821035ab4754a0c3db6a27c07b4e",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "languageId": "df41d661d8834f228d76c4b92a1b4c64",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    },
    {
      "code": "en-CA",
      "iso": "en-CA",
      "languageId": "c066e2f72e95408fac2ca158de9589c0",
      "files": [
        "i18n/utils/messageReader.ts"
      ]
    }
  ],
  "defaultLocale": "de-DE",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "data/i18n/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "storeId": "consumer-store",
  "i18nModules": []
}
  
  const vueI18nConfigLoader = async loader => {
    const config = await loader().then(r => r.default || r)
    if (typeof config === 'object') return config
    if (typeof config === 'function') return await config()
    return {}
  }

  const deepCopy = (src, des, predicate) => {
    for (const key in src) {
      if (typeof src[key] === 'object') {
        if (!(typeof des[key] === 'object')) des[key] = {}
        deepCopy(src[key], des[key], predicate)
      } else {
        if (predicate) {
          if (predicate(src[key], des[key])) {
            des[key] = src[key]
          }
        } else {
          des[key] = src[key]
        }
      }
    }
  }
  
  const mergeVueI18nConfigs = async (loader) => {
    const layerConfig = await vueI18nConfigLoader(loader)
    const cfg = layerConfig || {}
    
    for (const [k, v] of Object.entries(cfg)) {
      if(nuxtI18nOptions.vueI18n?.[k] === undefined || typeof nuxtI18nOptions.vueI18n?.[k] !== 'object') {
        nuxtI18nOptions.vueI18n[k] = v
      } else {
        deepCopy(v, nuxtI18nOptions.vueI18n[k])
      }
    }
  }

  nuxtI18nOptions.vueI18n = { messages: {} }
  
    
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "jsTsFormatResource": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "de-AT",
      "iso": "de-AT",
      "languageId": "7f041fa7dc6b4d9b830ba9d1ed061ebe",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "ja",
      "iso": "ja-JP",
      "languageId": "4ed13e1b86484557a1f106a44aad287f",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "languageId": "6bdf535e3b4940369a70a43e28f75cb7",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en-GB",
      "iso": "en-GB",
      "languageId": "053f020413df492c86ea58d279743cc1",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "languageId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "de-DE",
      "iso": "de-DE",
      "languageId": "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "de-CH",
      "iso": "de-CH",
      "languageId": "1644a5c39d3b439b917f6bd263b7f3a1",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en-IN",
      "iso": "en-IN",
      "languageId": "a7ec78935f5f40578af2a0cbc703d280",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en-CH",
      "iso": "en-CH",
      "languageId": "4081f4bda2fe4cd8afd97a44d49ea20e",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "it-CH",
      "iso": "it-CH",
      "languageId": "5f647817a9f1483d92c7b82543195369",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "fr-CH",
      "iso": "fr-CH",
      "languageId": "b3ad4309402a405cb995986fcc13e5f2",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "pl",
      "iso": "pl-PL",
      "languageId": "41f4b54c33144ba3a1f1511b38411f67",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "zo",
      "iso": "zh-HK",
      "languageId": "1a1f25c95a70424a946982d50e8978b0",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "languageId": "1a949cf9b9bd4cae8533866b6c70a5ff",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "it-IT",
      "iso": "it-IT",
      "languageId": "519a4d9fb36442f9a7536c4dc178f44e",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "languageId": "519a4d9fb36442f9a7536c4dc178f44e",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "languageId": "50ec64cb77454ee790f3b20208c1d7ea",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "ru",
      "iso": "ru-RU",
      "languageId": "6dd089602d5a489299ce3b81e04a10f4",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "languageId": "73781d40339e4a0aa93215018e866156",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "ko",
      "iso": "ko-KR",
      "languageId": "784eb941c8f843658642f7c0038ae837",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "languageId": "9cd3821035ab4754a0c3db6a27c07b4e",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "fr-CA",
      "iso": "fr-CA",
      "languageId": "2ff212d0ef184d898f8c4e70aadaf0dc",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en-GB",
      "languageId": "053f020413df492c86ea58d279743cc1",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "fr-FR",
      "iso": "fr-FR",
      "languageId": "9cd3821035ab4754a0c3db6a27c07b4e",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "languageId": "df41d661d8834f228d76c4b92a1b4c64",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    },
    {
      "code": "en-CA",
      "iso": "en-CA",
      "languageId": "c066e2f72e95408fac2ca158de9589c0",
      "files": [
        {
          "path": "i18n/utils/messageReader.ts"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
